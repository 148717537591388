<template>
  <v-dialog
    v-if="hasCheckin"
    v-model="dialog"
    persistent
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :color="checkedIn ? 'success white--text' : 'color3 color3Text--text'"
        v-on="on"
        rounded
        :loading="loading"
      >
        <v-icon class="mr-3">fas fa-{{checkedIn ? 'check' : 'map-marker-check'}}</v-icon>Check{{checkedIn ? 'ed' : null}} In
      </v-btn>
    </template>
    <v-card>
      <!-- Toolbar -->
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>
            <v-icon color="color1Text" class="mx-3">fas fa-map-marker-check</v-icon>
            Check In
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="color1Text" text small icon @click.stop="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      <!-- body -->
        <!-- upcoming -->
          <v-card-text class="text-center py-12" v-if="status.isUpcoming">
            <h1>Check-in is not open yet.</h1>
          </v-card-text>
        <!-- open -->
          <v-card-text v-else-if="status.isOpen" class="pt-4">
              <v-alert type="error" :value="true" text prominent v-if="individual && !tokenPlayer">
                The tournament host has requested that each player check in separately. Please use the link provided in the check-in email.
              </v-alert>
              <div class="text-center" v-else>
                <h1 class="pt-2">Yay{{individual && tokenPlayer ? `, ${tokenPlayer.firstName}` : ''}}</h1>
                <h1 class="pt-2"><v-icon x-large>far fa-grin-beam</v-icon></h1>
                <template v-if="checkedIn">
                  <h1>You are already all checked in!</h1>
                </template>
                <template v-else>
                  <h1 class="pt-2">It's time to check in! </h1>
                  <template v-if="team.waitlist">
                    <h2 class="pt-2">
                      <v-icon>fas fa-flushed</v-icon>
                      <div>Looks like you are on the waitlist</div>
                    </h2>
                    <div>Unfortunately, checking in will not get you in the tournament but, it will let us know you are still available if someone drops.</div>
                  </template>

                  <v-card v-if="tournament.noAgreeNoCheckin" :key="tournament.agreementStatuses.length">
                    <v-row>
                      <v-col cols="12" class="text-h6">
                        Waiver status - {{  tournament.noAgreeNoCheckin  }}
                      </v-col>
                      <v-col cols="12" v-for="p in team.players" :key="p.id" class="d-flex ali justify-center">
                        <div class="text-h5 mr-1">{{ p.name }}</div>
                        <v-chip
                          v-for="(a, i) in tournament.getPlayerAgreements(p.playerProfileId, division.id)"
                          :key="`${p.i}-${division.id}-${i}`"
                          :color="a.status === 'Signed' ? 'success' : 'error'"
                        >
                          <v-icon small v-if="a.status === 'Signed'">
                            fas fa-check
                          </v-icon>
                          <player-agreement-dialog
                            v-else
                            :playerProfileId="p.i"
                            :agreementId="a.id"
                            :agreement="a.name"
                            color="white"
                            icon="fas fa-times"
                          ></player-agreement-dialog>
                        </v-chip>
                      </v-col>
                      <v-col class="error--text">
                        All waivers must be signed before check in!
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-btn
                    color="color3 color3Text--text"
                    class="mt-3"
                    large
                    @click.stop="checkin(true)"
                    :loading="checkingIn || loading || loading2"
                    :disabled="disabled"
                  >
                    Check in
                  </v-btn>
                </template>
              </div>
            <!-- Teammates Status -->
              <div v-if="individual" class="mt-6">
                <h2>Your teammate's status:</h2>
                <v-alert type="success" :value="true" text prominent v-if="checkedInPlayers">
                  {{checkedInPlayers}}
                </v-alert>
                <v-alert type="info" :value="true" text prominent v-if="nonCheckedInPlayers">
                  {{nonCheckedInPlayers}}
                </v-alert>
              </div>
              <template v-if="!checkedIn">
                <div class="mt-6 text-right" v-if="!noShow">Not going to make the tournament? <v-btn color="color3" text small @click.stop="noShow = true">click here</v-btn></div>
                <v-expand-transition>
                  <div v-if="noShow" class="pt-4">
                    <v-row dense>
                      <v-col cols="8" offset="2">
                        <div>If your partner is coming and playing with someone else, please ask your partner to check in and make the partner change</div>
                        <div class="mt-4">If both player are not making the event, please close this window and click the withdraw tab.</div>
                        <v-btn color="color3" text @click.stop="dialog = false">OK</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </template>
              <template v-else>
                  <div class="text-right pt-12">
                    <v-btn
                      color="color3"
                      text x-small
                      @click="undo = !undo"
                    >
                      checked in by accident?
                    </v-btn>
                  </div>
                  <v-expand-transition>
                    <div v-if="undo" class="text-center">
                      <v-btn
                        color="color3 color3Text--text"
                        class="mt-3"
                        large
                        @click.stop="checkin(false)"
                        :loading="checkingIn"
                      >
                        undo Check in
                      </v-btn>
                    </div>
                  </v-expand-transition>
              </template>
          </v-card-text>

        <v-card-text v-else class="text-center">
          <h1>Check-in has closed.</h1>
        </v-card-text>
        <!-- open -->

    </v-card>
  </v-dialog>
</template>

<script>
import OnlineCheckinWindow from '@/classes/OnlineCheckinWindow'
import { mapGetters } from 'vuex'
const PlayerAgreementDialog = () => import('@/components/Agreements/PlayerAgreementDialog')

export default {
  props: ['tournament', 'team', 'division', 'tokenStatus'],
  data () {
    return {
      dialog: false,
      loading: false,
      loading2: false,
      checkingIn: false,
      windows: null,
      noShow: false,
      undo: false
    }
  },
  computed: {
    ...mapGetters(['getTeamToken', 'user']),
    hasCheckin () {
      return (this.tournament && this.division && this.tournament.onlineCheckin.includes(this.division.id)) // || (this.user && this.user.id === 1)
    },
    checkedIn: {
      get () {
        return this.team && (this.team.checkedIn || (this.tokenPlayer && this.tokenPlayer.checkedIn))
      },
      set (val) {
        if (this.individual) {
          if (this.tokenPlayer) this.tokenPlayer.checkedIn = val
        } else {
          this.team.checkedIn = val
        }
      }
    },
    tokenPlayer () {
      return this.tokenStatus && this.tokenStatus.tournamentTeamMemberId && this.team && this.team.players && this.team.players.find(f => f.id === this.tokenStatus.tournamentTeamMemberId)
    },
    myWindow () {
      return this.windows && this.windows.find(f => f.divisionId === this.division.id)
    },
    individual () {
      return this.myWindow && !this.myWindow.team
    },
    checkedInPlayers () {
      var notId = this.tokenPlayer ? this.tokenPlayer.id : 0
      var p = this.team && this.team.players.filter(f => f.checkedIn && f.id !== notId).map(m => m.name)
      return p.length && `${this.$options.filters.formatArray(p)} ${p.length > 1 ? 'have' : 'has'} already checked in`
    },
    nonCheckedInPlayers () {
      var notId = this.tokenPlayer ? this.tokenPlayer.id : 0
      var p = this.team && this.team.players.filter(f => !f.checkedIn && f.id !== notId).map(m => m.name)
      return p.length && `${this.$options.filters.formatArray(p)} ${p.length > 1 ? 'have' : 'has'} not checked in`
    },
    teamToken () {
      return this.getTeamToken(this.team.id, this.$route)
    },
    status () {
      const w = this.myWindow
      return {
        isUpcoming: w && w.isUpcoming,
        isClosed: w && w.isClosed,
        isOpen: (w && w.isOpen), // || (this.user && this.user.id === 1),
        na: !w || w.statusNa
      }
    },
    disabled () {
      return this.loading || this.loading2 || (this.tournament.noAgreeNoCheckin && this.tournament.agreementStatuses.length > 0 && !!this.team.players.find(p => {
        return !this.tournament.checkPlayerAgreements(p.playerProfileId, this.division.id)
      }))
    }
  },
  methods: {
    loadWindows () {
      this.loading = true
      this.$VBL.tournament.checkin.getWindows(this.tournament.id)
        .then(r => {
          this.init(r.data)
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    checkin (val) {
      this.checkingIn = true
      this.$VBL.team.checkIn(this.team.id, val, this.teamToken)
        .then(() => {
          this.checkedIn = val
          this.dialog = false
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.checkingIn = false
        })
    },
    init (data) {
      this.windows = data.map(m => new OnlineCheckinWindow(m))
      if (this.status.isOpen && !this.checkedIn) {
        if (!this.individual || (this.tokenPlayer && this.tokenPlayer.checkedIn)) {
          this.dialog = true
        }
      }
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.noShow = this.undo = false
      }
    }
  },
  components: {
    PlayerAgreementDialog
  },
  mounted () {
    if (this.hasCheckin) {
      this.loadWindows()
    }
    if (this.tournament.noAgreeNoCheckin) {
      this.loading2 = true
      this.tournament.getAgreementStatus()
        .finally(() => { this.loading2 = false })
    }
  }
}
</script>

<style scoped>
.v-card__text {
  line-height: initial !important;
}
</style>
