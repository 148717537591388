import DtoUpdate from './DtoUpdate'
import objectHasProperties from '@/helpers/ObjectHasProperties'
import moment from 'moment'

export default class OnlineCheckinWindow {
  constructor (dto) {
    this.id = 0
    this.divisionId = null
    this.dtStart = null
    this.dtEnd = null
    this.dtStartUTC = null
    this.dtEndUTC = null
    this.team = true
    this.active = false
    this.complete = false

    if (dto) {
      this.update(dto)
    }
  }

  // methods
  update (dto) {
    dto = dto || {}
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = []
    DtoUpdate(dto, this, exclude)
    this.dtStart = this.dtStart.replace('Z', '')
    this.dtEnd = this.dtEnd.replace('Z', '')
  }

  get type () {
    return this.active ? this.team ? 'Team Check-in' : 'Player Check-in' : 'No Online Check-in'
  }

  set type (val) {
    switch (val) {
      case 'Team Check-in':
        this.active = this.team = true
        break
      case 'Player Check-in':
        this.active = true
        this.team = false
        break
      default:
        this.active = this.team = false
    }
  }

  get dto () {
    return {
      id: this.id,
      divisionId: this.divisionId,
      dtStart: this.dtStart,
      dtEnd: this.dtEnd,
      team: this.team,
      active: this.active,
      complete: this.complete
    }
  }

  getDto (comp) {
    comp = comp || {}
    if (this.id === 0) return this.dto
    const r = {}
    if (this.dtStart !== comp.dtStart) r.dtStart = this.dtStart
    if (this.dtEnd !== comp.dtEnd) r.dtEnd = this.dtEnd
    if (this.team !== comp.team) r.team = this.team
    if (this.active !== comp.active) r.active = this.active

    if (objectHasProperties(r)) {
      r.id = this.id
      r.divisionId = this.divisionId
      return r
    }
    return null
  }

  get start () {
    return this.dtStartUTC && moment(this.dtStartUTC)
  }

  get end () {
    return this.dtEndUTC && moment(this.dtEndUTC)
  }

  get isOpen () {
    return this.start && this.end && moment().isBetween(this.start, this.end, 'minute', '[]')
  }

  get isClosed () {
    return this.end && moment().isAfter(this.end, 'minute')
  }

  get isUpcoming () {
    return this.start && moment().isBefore(this.start, 'minute')
  }

  get statusNa () {
    return !(this.start && this.end)
  }
}
